@import 'settings';

header {
  line-height: 1.1;
  margin: $global-margin 0 2 * $global-margin 0;

  @include breakpoint(medium) {
    margin: 2 * $global-margin 0;
  }
}

.title-img {
  height: 45px;
  width: 310px;
}

.buy-coffee {
  height: 45px;
  width: 160px;
}
