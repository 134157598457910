@import 'settings';

.fa-heart {
  color: get-color(alert);
  margin: 0 .5 * $global-margin;
}

.avatar {
  height: 24px;
  margin: 0 .5 * $global-margin;
  vertical-align: text-bottom;
}

.logotype {
  margin: 0 $global-margin;
}
