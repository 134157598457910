@import 'settings';

.table {
  background: #292629;
  border-radius: .5rem;
  box-shadow: 0 0 1px $light-gray;
  overflow: hidden;
  position: relative;
  top: - 1.75 * $global-margin;

  .thead {
    background: get-color(primary);
    height: 50px;
  }

  label {
    color: $white;
    font-weight: bold;
  }
}

textarea {
  font-family: monospace;
  font-size: 14px;
  height: 500px;
  max-height: 30vh;
  margin-bottom: 0;
  resize: none;
  border: 0;
  box-shadow: none !important;
  padding: $global-padding;
  overflow: scroll;
  background: #292629 !important;
  color: $white;

  @include breakpoint(medium) {
    max-height: 50vh;
  }

  &[readonly] {
    background: #292629;
    cursor: text;
  }

  &:focus {
    border: 0;
    outline: none;
  }
}

.copy {
  border: 0 !important;
  color: get-color(warning) !important;
  margin: 0;

  &:hover {
    background-color: $light-gray !important;
  }
}

.textarea {
  border-bottom: 1px solid rgba($white, .25);

  .small-order-1 {
    border-right: 1px solid rgba($white, .25);
  }
}

.stripe {
  background: get-color(warning);
  padding: .75 * $global-padding $global-padding 2.25 * $global-padding 3 * $global-padding;
  border: none;

  @include breakpoint(small only) {
    padding-left: $global-padding;
  }
}

.bug {
  color: $dark-gray;
  font-style: italic;
}
